import { FC } from 'react';
import { ConfigProvider, theme } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import '@pqina/pintura/pintura.css';

import Routes from '@/routes';
import GlobalStyle from '@/styles/global';
import LoadingBlocker from './components/LoadingBlocker';
import { ConfirmationProvider } from './pages/Login/Components/UserConfirmationContext';

const queryClient = new QueryClient();

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: '#6F56BC',
            borderRadius: 0,
          },
        }}
      >
        <ConfirmationProvider>
          <LoadingBlocker>
            <Routes />
          </LoadingBlocker>
          <GlobalStyle />
        </ConfirmationProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
//force rebuild